var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    shot: true,
    selected: _vm.selected,
    unselectable: true,
    stdby: _vm.entity ? _vm.entity.is_casting_standby : false,
    'text-mode': _vm.textMode
  },attrs:{"id":_vm.entity.id},on:{"click":function($event){return _vm.onClicked($event)}}},[_c('div',{staticClass:"flexrow"},[_c('span',{staticClass:"flexrow flexrow-item"},[_c('entity-thumbnail',{attrs:{"entity":{},"height":30,"empty-width":40,"empty-height":30,"preview-file-id":_vm.previewFileId}})],1),_c('div',{staticClass:"shot-name flexrow-item"},[_vm._v(" "+_vm._s(_vm.name)+" ")]),_c('div',{staticClass:"standby-column flexrow-item"},[_c('input',{style:([_vm.isCurrentUserManager ? {cursor: 'pointer'} : {cursor: 'auto'}]),attrs:{"type":"checkbox","disabled":!_vm.isCurrentUserManager},domProps:{"checked":_vm.entity ? _vm.entity.is_casting_standby : false},on:{"input":function (event) { return _vm.onStandbyChanged(_vm.entity, event); }}})]),(!_vm.isShowInfosBreakdown)?_c('div',{staticClass:"description-column flexrow-item"},[(_vm.readOnly)?_c('div',{staticClass:"tooltip-text",domProps:{"innerHTML":_vm._s(_vm.compileMarkdown(_vm.entity.description))}}):_c('textarea',{ref:"text",staticClass:"tooltip-editor",domProps:{"value":_vm.entity.description},on:{"input":function (event) { return _vm.onDescriptionChanged(_vm.entity, event); }}})]):_vm._e(),_vm._l((_vm.visibleMetadataDescriptors),function(descriptor,j){return (!_vm.isShowInfosBreakdown)?_c('div',{key:'desc' + _vm.entity.id + '-' + descriptor.id,staticClass:"metadata-descriptor flexrow-item",attrs:{"title":_vm.entity.data ? _vm.entity.data[descriptor.field_name] : ''}},[(descriptor.choices.length === 0 && (_vm.isCurrentUserManager
        || _vm.isSupervisorInDepartments(descriptor.departments)))?_c('input',{staticClass:"input-editor",domProps:{"value":_vm.getMetadataFieldValue(descriptor, _vm.entity)},on:{"input":function (event) { return _vm.onMetadataFieldChanged(_vm.entity, descriptor, event); },"keyup":function($event){if(!$event.ctrlKey){ return null; }return (function (event) { return _vm.onInputKeyUp(event, _vm.getIndex(_vm.i, _vm.k), j); }).apply(null, arguments)}}}):(descriptor.choices.length > 0 && _vm.getDescriptorChecklistValues(descriptor).length > 0)?_c('div',{staticClass:"metadata-value selectable"},_vm._l((_vm.getDescriptorChecklistValues(descriptor)),function(option,i){return _c('p',{key:((_vm.entity.id) + "-" + (descriptor.id) + "-" + i + "-" + (option.text) + "-div")},[_c('input',{style:([_vm.isCurrentUserManager
              || _vm.isSupervisorInDepartments(descriptor.departments) ?
                {cursor: 'pointer'} : {cursor: 'auto'}]),attrs:{"type":"checkbox","id":((_vm.entity.id) + "-" + (descriptor.id) + "-" + i + "-" + (option.text) + "-input"),"disabled":!(_vm.isCurrentUserManager
              || _vm.isSupervisorInDepartments(descriptor.departments))},domProps:{"checked":_vm.getMetadataChecklistValues(descriptor, _vm.entity)[option.text]},on:{"change":function (event) { return _vm.onMetadataChecklistChanged(_vm.entity, descriptor, option.text, event); }}}),_c('label',{style:([_vm.isCurrentUserManager
              || _vm.isSupervisorInDepartments(descriptor.departments) ?
                {cursor: 'pointer'} : {cursor: 'auto'}]),attrs:{"for":((_vm.entity.id) + "-" + (descriptor.id) + "-" + i + "-" + (option.text) + "-input")}},[_vm._v(" "+_vm._s(option.text)+" ")])])}),0):(_vm.isCurrentUserManager
        || _vm.isSupervisorInDepartments(descriptor.departments))?_c('span',{staticClass:"select"},[_c('select',{staticClass:"select-input",on:{"keyup":function($event){if(!$event.ctrlKey){ return null; }return (function (event) { return _vm.onInputKeyUp(event, _vm.getIndex(_vm.i, _vm.k), j); }).apply(null, arguments)},"change":function (event) { return _vm.onMetadataFieldChanged(_vm.entity, descriptor, event); }}},_vm._l((_vm.getDescriptorChoicesOptions(descriptor)),function(option,i){return _c('option',{key:("desc-value-" + (_vm.entity.id) + "-" + (descriptor.id) + "-" + i + "-" + (option.label) + "-" + (option.value)),domProps:{"value":option.value,"selected":_vm.getMetadataFieldValue(descriptor, _vm.entity) === option.value}},[_vm._v(" "+_vm._s(option.label)+" ")])}),0)]):_c('span',{staticClass:"metadata-value selectable"},[_vm._v(" "+_vm._s(_vm.getMetadataFieldValue(descriptor, _vm.entity))+" ")])]):_vm._e()}),(_vm.isShowInfosBreakdown)?_c('div',{staticClass:"asset-list flexrow-item"},[_vm._l((_vm.assets),function(typeAssets){return _c('div',{key:typeAssets.length > 0 ? typeAssets[0].asset_type_name : '',staticClass:"asset-type-line flexrow"},[_c('span',{staticClass:"asset-type-name flexrow-item"},[_vm._v(" "+_vm._s(typeAssets.length > 0 ? typeAssets[0].asset_type_name : '')+" ("+_vm._s(typeAssets.reduce(function (acc, a) { return acc + a.nb_occurences; }, 0))+") ")]),_c('div',{staticClass:"asset-type-items flexrow-item"},_vm._l((typeAssets),function(asset){return _c('asset-block',{key:asset.id,staticClass:"flexrow-item",attrs:{"asset":asset,"nb-occurences":asset.nb_occurences,"read-only":_vm.readOnly,"text-mode":_vm.textMode},on:{"edit-label":_vm.onEditLabelClicked,"remove-one":_vm.removeOneAsset,"remove-ten":_vm.removeTenAssets}})}),1)])}),(_vm.assets.length === 0)?_c('div',{staticClass:"asset-type-line flexrow empty mt05 mb05"},[_vm._v(" "+_vm._s(_vm.$t('breakdown.empty'))+" ")]):_vm._e()],2):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }